import React, { FC, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReducerStateProperties } from "../../commons/baseReducer";
import { GetTournamentApplicationsAdmin, TournamentApplicationAdminView, TournamentClass } from "../../commons/tournament/model";
import { SportResultsTable, SportResultsTableRow } from "../../commons/ui/table/sportresult.table";
import { RootState } from "../../store/rootReducer";
import { default as lodash } from 'lodash';
import { getMomentDateOutTime } from "../../commons/helpers";
import { AgeGroupApiViewModel } from "../../commons/ageGroup/model";
import { ClassGroupApiVIewModel } from "../../commons/classGroup/model";
import { getPage } from "../../commons/utils/ui.utils";
import { isPageLiveScore } from "../../commons/utils/businessUtils";
import { LeagueMatchLivescoreCodeForm } from "../leagueMatch/leagueMatchLivescoreCodeForm";
import TableCell from "../muiComponents/core/TableCell";
import { Check } from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type props = {

  isLivescore: boolean,
  isReadonly: boolean,
  isUmpire: boolean,
}
interface TournamentAgeGroupClassId {
  classIds: number[],
  ageGroupId: number
}

export const TournamentTable: FC<props> = (props) => {
  const urlPath = props.isUmpire ? "umpire" : "count";
  const navigate = useNavigate();
  const [selectedTournamentClass, setSelectedTournamentClass] = useState<TournamentClass>(null)
  const [selectedTournamentAdmin, setSelectedTournamentAdmin] = useState<TournamentApplicationAdminView>(null)
  const [isLiveScore] = useState<boolean>(isPageLiveScore())
  const [hasToOpenDialog, setHasToOpenDialog] = useState<boolean>(false);
  const tournamentState = useSelector<RootState>((state) => {
    return state.tournaments?.tournaments;
  }) as ReducerStateProperties<GetTournamentApplicationsAdmin>;
  const classGroupState = useSelector<RootState>((state) => {
    return state.classGroups.model
  }) as ClassGroupApiVIewModel[]
  const ageGroupState = useSelector<RootState>((state) => {
    return state.ageGroups.model;
  }) as AgeGroupApiViewModel[]
  const getClassName = (classId: number) => {
    return classGroupState?.find(x => x.classID === classId)?.className
  }
  const getAgeGroupCode = (ageGroupId: number) => {
    return ageGroupState?.find(x => x.ageGroupId === ageGroupId)?.ageGroupName
  }
  let newTableData: SportResultsTableRow[];
  if (!lodash.isEmpty(tournamentState.model)) {
    const tournamentsClasses = tournamentState.model.tournaments;
    newTableData = tournamentState.model.tournamentAdmins.map((item, index) => {
      const tournamentClasses = tournamentsClasses?.filter(tour => tour.tournamentID === item.tournamentID)
      const classStates = Array.from(new Set(tournamentClasses.map(x => x.tournamentState)).values());

      const tournamentageGroupClasses: TournamentAgeGroupClassId[] = tournamentClasses.map(tc => {
        const ageGroupClassId: TournamentAgeGroupClassId = {
          ageGroupId: tc.ageGroupID,
          classIds: tournamentClasses.filter(t => t.ageGroupID === tc.ageGroupID).map(x => {
            return x.classID
          })
        }
        if (ageGroupClassId)
          return ageGroupClassId
      })
      const whitespace = ' '
      const tournamentAgeGroupClassesStr = Array.from(new Set(
        tournamentageGroupClasses
          .map(x => `${getAgeGroupCode(x.ageGroupId)} ${x.classIds.sort((a, b) => a - b)
            .map(x => getClassName(x))
            .join(whitespace)}`)
      ).values())

      const cells: JSX.Element[] = [
        <TableCell aria-label={headerLabels[0]} scope='right'>{getMomentDateOutTime(item.dateFrom)}</TableCell>,
        <TableCell aria-label={headerLabels[1]} align='right'>{getMomentDateOutTime(item.dateTo)}</TableCell>,
        <TableCell aria-label={headerLabels[2]} align='right'>{item.clubName}</TableCell>,
        <TableCell aria-label={headerLabels[3]} align='right'>{item.title}</TableCell>,
        <TableCell aria-label={headerLabels[4]} align='right'>{tournamentAgeGroupClassesStr.join(',')}</TableCell>,
        !isLiveScore &&
        <TableCell aria-label={headerLabels[5]} align='right'>{item.liveScore ? <Check htmlColor="#4caf50" /> : <ErrorOutlineIcon color="secondary" />}</TableCell>,
      ]
      const result: SportResultsTableRow = {
        id: item.tournamentID,
        cells
      }
      return result;
    });
  }

  const onTournamentSelect = (event: any) => {
    const selectedTournamentClass = tournamentState?.model.tournaments.find(x => x.tournamentID === event.id);
    const selectedTournamentAdmin = tournamentState?.model?.tournamentAdmins.find(x => x.tournamentID === event.id)
    setSelectedTournamentClass(selectedTournamentClass);
    setSelectedTournamentAdmin(selectedTournamentAdmin)

    if (!isLiveScore) {
      setHasToOpenDialog(true);
    } else {
      navigate(getPage(`${urlPath}/tournament/${selectedTournamentClass.tournamentClassID}`, props.isLivescore))
    }
  }

  const onLiveScoreSubmit = (code: string) => {
    navigate(getPage(`${urlPath}/tournament/${selectedTournamentClass.tournamentClassID}`, props.isLivescore, props.isReadonly))
  }
  const isLoading = tournamentState.isLoading && tournamentState.loadStarted;

  return (
    <React.Fragment>
      <React.Fragment>
        <SportResultsTable isRowsLoading={isLoading} data={newTableData} headers={isLiveScore ? headers.slice(0, headers.length - 1) : headers} footerLabel="Turneringer pr. side"
          tableLabel="tilgængelige kampe" onRowSelect={onTournamentSelect} />
        {
          hasToOpenDialog &&
          <LeagueMatchLivescoreCodeForm liveScoreCode={selectedTournamentAdmin?.liveScoreCode}
            onClose={() => setHasToOpenDialog(false)}
            onLiveScoreCodeSubmit={onLiveScoreSubmit} />
        }

      </React.Fragment>
    </React.Fragment>
  )
}

const headerLabels = [
  'Dato fra', 'Dato til', 'Klub', 'Title', 'Række', 'Livescore'
  //'Turneringsnummer', 'Navn', 'Dato fra', 'Dato til', 'Arrangør', 'Række', 'Livescore', 'State'
]


const headers: JSX.Element[] = [
  <TableCell>{headerLabels[0]}</TableCell>,
  <TableCell align="right">{headerLabels[1]}</TableCell>,
  <TableCell align="right">{headerLabels[2]}</TableCell>,
  <TableCell align="right">{headerLabels[3]}</TableCell>,
  <TableCell align="right">{headerLabels[4]}</TableCell>,
  !isPageLiveScore() && <TableCell align="right">{headerLabels[5]}</TableCell>,
  //<TableCell align="right">{headerLabels[6]}</TableCell>,
  //<TableCell align="right">{headerLabels[7]}</TableCell>,
]
