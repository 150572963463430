import React, { useState, useEffect, FC } from 'react';
import Dialog from "../muiComponents/core/Dialog";
import Grid from "../muiComponents/core/Grid";

type props = {
  userInteraction: number,
  isMatchStart: boolean
}

const MatchCounterDialogBeforeClickStart: FC<props> = (props) => {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if(props.userInteraction !== 0 && props.isMatchStart === false) {
      setOpenDialog(true);
    }
  }, [props, props.isMatchStart, props.userInteraction]);

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  }

  return (
    <Dialog
      className='dialogCustom'
      onClose={() => { handleDialog() }} open={openDialog}>
      <div>
        <Grid container style={{
          maxWidth: '500px',
          maxHeight: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '10px'
        }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '2rem' }}>{`Du kan ikke give point f\u00F8r du har trykket p\u00E5 'Start'`}</p>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
};

export default MatchCounterDialogBeforeClickStart