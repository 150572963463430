import {AgeGroupClass, UnifiedTournamentMatch, TournamentEvent, TournamentLevelEnum} from '../../commons/tournament/model';
import React, { FC } from "react";
import { SportResultsTable, SportResultsTableRow } from "../../commons/ui/table/sportresult.table";
import {default as lodash} from 'lodash';
import { getMomentDate } from '../../commons/helpers';
import { MatchSetResult } from '../matchCounter';
import TableCell from "../muiComponents/core/TableCell";

type props = {
    tournamentEvents: TournamentEvent[],
    isLoading?: boolean,
    onSelect: (event: TournamentEventRowId) => void,
    showOnlyEmpty: boolean,
    ageGroupClasses: AgeGroupClass[]
}

export const TournamentEventTable: FC<props> = (props) => {    
    let newTableData: SportResultsTableRow[];    
    const processLevelText = ( matchEvent: UnifiedTournamentMatch ) =>{
        
        if(matchEvent.levelNumber !== null){
            switch(matchEvent.levelNumber){
                case TournamentLevelEnum.Final:
                    matchEvent.levelText = 'Finale';
                    matchEvent.sortIndex = 0
                    break;
                case TournamentLevelEnum.Semifinal:
                    matchEvent.levelText = 'Semifinaler';
                    matchEvent.sortIndex = 1
                    break;
                case TournamentLevelEnum.Quaterfinals:
                    matchEvent.levelText = 'Kvartfinaler';
                    matchEvent.sortIndex = 2
                    break;
                default:
                    matchEvent.levelText = `1 / ${matchEvent.levelNumber}`;
                    matchEvent.sortIndex = 3;
                    break;                
            }
        }else{
            matchEvent.levelText = `Pulje ${matchEvent.groupNumber}`;
            matchEvent.sortIndex = 3 + matchEvent.groupNumber
        }
        return matchEvent;
    }

    const mapResultToMatchSet = (sets: RegExpMatchArray, index: number,
        matchResults: MatchSetResult[] = new Array<MatchSetResult>()) => {
        if(sets.length > 0){
            const score2 = new Number(sets.pop()).valueOf();
            const score1 = new Number(sets.pop()).valueOf();
            const matchResult: MatchSetResult = {
                score1,
                score2,
                setIndex: index
            }
            matchResults.push(matchResult);
            mapResultToMatchSet(sets, index - 1, matchResults)
        }
        return matchResults;
    }


    const processResult = (result: string) => {
        if(result){
            var numberPattern = /\d+/g;
            const sets = result.match(numberPattern);
            if(sets.length > 0){
                while((sets.length % 2) === 1){
                    sets.push('0');
                }
                const matchResults = mapResultToMatchSet(sets, sets.length / 2);

                return matchResults;
            }
            return null;
        }
    }

    const isMatchWithoutResult = (result: string) => {
        const matchResults = processResult(result);
        const winningScore = 21
        let homeWons = 0;
        let awayWons = 0;
        if(matchResults){
            matchResults.forEach(match => {
                if(match.score1 >= winningScore && (match.score1 - match.score2) >= 2){
                    homeWons += 1
                }
                if(match.score2 >= winningScore && (match.score2 - match.score1) >= 2){
                    awayWons += 1
                }
            })
            return homeWons < 2 && awayWons < 2;
        }
        return true;
    }

    if(!lodash.isEmpty(props.tournamentEvents)){
        
            newTableData = props.tournamentEvents.map((tournament, index) => {
                const ageGroupName = props.ageGroupClasses
                ?.find(x => x.ageGroupId === tournament?.tournamentClass?.ageGroupID && x.classId == tournament.tournamentClass.classID)
                ?.ageGroupClassName

            const processedEventMatch = tournament.unifiedTournamentMatches
            .map(x => processLevelText(x))
            .filter(x => {
                if(props.showOnlyEmpty){
                    return isMatchWithoutResult(x.result)
                }
                return x
            })
            .sort((a,b) => {
                if(a.matchTime && b.matchTime){
                    return new Date(a.matchTime)?.getTime() - new Date(b.matchTime)?.getTime()
                }
                return -1;
            })
            .sort((a, b) => a.sortIndex - b.sortIndex) as UnifiedTournamentMatch[]

            return processedEventMatch.map(item => {
                const homePlayers = item.homePlayers
                ?.map(x => <p>{x?.name}, {x?.clubName}</p>);
                
                const awayPlayers = item.awayPlayers
                ?.map(x => <p>{x?.name}, {x?.clubName}</p>);
                if(item.result){
                    console.log()
                }
                const cells: JSX.Element[] = [
                    <TableCell aria-label={headerLabels[0]} scope='row'>{getMomentDate(item.matchTime)}</TableCell>,
                    <TableCell aria-label={headerLabels[1]} align='right'>{item?.levelText}</TableCell>,
                    <TableCell aria-label={headerLabels[2]} align='right'>{homePlayers}</TableCell>,
                    <TableCell aria-label={headerLabels[3]} align='right'>{awayPlayers}</TableCell>,
                    <TableCell aria-label={headerLabels[4]} align='right'>{item.result}</TableCell>,
                    <TableCell aria-label={headerLabels[5]} align='right'>{ageGroupName}</TableCell>,
                   
                ]
                const id: TournamentEventRowId = { 
                  matchId: item.matchId.toString(),
                  tournamentEventId: item.tournamentEventId,
                  isPossibleToCount: item.isPossibleToCount
                }
                const result: SportResultsTableRow = {
                    id,
                    cells
                }
                return result;
            })
        }).reduce((a,b) => a.concat(b));
    }

    return(
        <React.Fragment>
            {(newTableData || props.isLoading) &&
                <SportResultsTable isRowsLoading={props.isLoading} data={newTableData} headers={headers} footerLabel="Kampe pr. side" 
                tableLabel="Tilgængelige kampe" onRowSelect={(event) => props.onSelect(event.id)}/>
            }
        </React.Fragment>
    )
}

const headerLabels = [
    'Kamptidspunkt','Stage','Spillere','Spillere','Resultat', 'Årgang'
]


const headers: JSX.Element[] = [
    <TableCell>{headerLabels[0]}</TableCell>,
    <TableCell align="right">{headerLabels[1]}</TableCell>,
    <TableCell align="right">{headerLabels[2]}</TableCell>,
    <TableCell align="right">{headerLabels[3]}</TableCell>,
    <TableCell align="right">{headerLabels[4]}</TableCell>,
    <TableCell align="right">{headerLabels[5]}</TableCell>
]

export interface TournamentEventRowId{
    matchId: string,
    tournamentEventId: number,
    isPossibleToCount: boolean,
}