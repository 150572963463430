import React, { useState, useEffect, FC } from 'react';
import { BlockMatchToSave } from '../../commons/blockMatch/model';
import { blockMatchActions } from '../../commons/blockMatch/blockMatchActionReducer';
import { useDispatch } from 'react-redux';

type props = {
  userInteractWithPoints: number;
  unlockMatch: () => BlockMatchToSave,
  setBlockMatchBtn: (isBLockMatchBtn: boolean) => void,
  blockMatchBtnStartLeave: boolean
}

const MatchCounterInteraction: FC<props> = (props) => {
  const [secondsInactive, setSecondsInactive] = useState(0);

  const threeMinutes = 180;
  const dispatch = useDispatch();
  const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timeout | null>(null);
  const [payload, setPayload] = useState(props.unlockMatch);
  const [stopCounting, setStopCounting] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    interval = setInterval(() => {
      setSecondsInactive((prevSeconds) => prevSeconds + 1);
    }, 1000);

    setCountdownInterval(interval);

    return () => {
      clearInterval(interval);
      setSecondsInactive(0);
      dispatch(blockMatchActions.deleteBlockMatch(payload));
    };
  }, []);

  useEffect(() => {
    if (props.userInteractWithPoints !== 0 && props.blockMatchBtnStartLeave) {
      setSecondsInactive(0);
      setCountdownInterval(countdownInterval);
    }
  }, [props.userInteractWithPoints])

  useEffect(() => {
    if (secondsInactive === threeMinutes && props.blockMatchBtnStartLeave) {
      setStopCounting(true);

      props.setBlockMatchBtn(false)
    } else if (secondsInactive >= threeMinutes) {
      setSecondsInactive(0);
      setStopCounting(true);
    }
  }, [secondsInactive, stopCounting, countdownInterval]);

  return null;
};

export default MatchCounterInteraction