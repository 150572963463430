import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { ClubResponse } from "../../commons/clubs/model";
import { SelectItem } from "../../commons/common.types";
import { CourtTournamentEventMatchResponse } from "../../commons/courts/model";
import { getMode } from "../../commons/utils/ui.utils";
import { RootState } from "../../store/rootReducer";
import * as lodash from 'lodash'
import { clubActions } from "../../commons/clubs/clubsActionReducer";
import { courtsActions } from "../../commons/courts/courtsActionReducer";
import { TournamentEvent } from "../../commons/tournament/model";
import { PlayerDataBasic } from "../../commons/player/model";
import makeStyles from "../muiComponents/core/makeStyles";
import Popper from "../muiComponents/core/Popper";
import Grid from "../muiComponents/core/Grid";
import FormControl from "../muiComponents/core/FormControl";
import TextField from "../muiComponents/core/TextField";
import Autocomplete from "../muiComponents/core/Autocomplete";
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
    clubId: number,
    tournamentClassId: number,
    tournamentEventId: number,
    matchId: number,
    tournamentEvents: TournamentEvent[],
    handleMatchChange: (tournamentEventId: number, matchId: number) => void
}

const styles = () =>{
    return {
        popper: {
            width: "fit-content"
        }
    }
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const PopperMy = function (props: any) {
    return <Popper {...props} style={styles().popper} placement="bottom-start" />;
 };

export const CourtTournamentMatchesForm: FC<props> = (props) => {
    const classes = useStyles();
    const {clubId, tournamentEventId, tournamentClassId: tournamentId, matchId} = props
    const dispatch = useDispatch();
    const courtsMatchesState = useSelector<RootState>(x => {
        return x.courts.courtsTournamentMatches
    }) as ReducerStateProperties<CourtTournamentEventMatchResponse[]>
    const clubsState = useSelector<RootState>((x) => {
        return x.clubs.all;
    }) as ReducerStateProperties<ClubResponse[]>    
    const [selectItems, setSelectItems] = useState<SelectItem[]>(new Array<SelectItem>())    
    const mode = useSelector<RootState>(x => x.mode.model ?? getMode()) as 'light' | 'dark'
    const handleAssign = (option: SelectItem) =>{
        const matches = props.tournamentEvents.map(x => x.unifiedTournamentMatches).reduce((a,b) => a.concat(b))
        const selectedTournamentEvent = matches.find(x => getSelectItemId(x.tournamentEventId, x.matchId) == option.value)
        props.handleMatchChange(selectedTournamentEvent.tournamentEventId, selectedTournamentEvent.matchId)
    }
    useEffect(() => {
        if(!lodash.isEmpty(clubsState?.model) && clubsState.status == RequestStatus.NotInitiated){
            dispatch(clubActions.getAll())
        }
    }, [clubsState.model])

    const getSelectItemId = (tournamentEventId: number, matchId: number) => {
        return `${tournamentEventId}_${matchId}`
    }
    useEffect(() => {
        if(lodash.isEmpty(courtsMatchesState?.model) && courtsMatchesState.status == RequestStatus.NotInitiated){
            dispatch(courtsActions.getCourtTournamentMatches(clubId,tournamentId, matchId,tournamentEventId))
        }else if(!lodash.isEmpty(courtsMatchesState?.model)){
            const newSelectItemIds = courtsMatchesState?.model
            ?.map(x => `${x?.tournamentEventMatch?.tournamentEventId}_${x?.tournamentEventMatch?.matchId}`)
            const selectItemIds = selectItems.map(x => x?.value)

            if(!lodash.isEqual(newSelectItemIds, selectItemIds)){
                const selectItems = courtsMatchesState?.model.map(c => {
                    const tournamentEventMatch = c?.tournamentEventMatch
                    if(!tournamentEventMatch) return undefined
                    const selectItem: SelectItem = {
                        name: `${getSelectItemName(c,false)}`,
                        value: getSelectItemId(tournamentEventMatch.tournamentEventId, tournamentEventMatch.matchId)
                    }
                    return selectItem
                }).filter(c => c !== undefined)
                setSelectItems(selectItems)
            }
        }
    }, [courtsMatchesState.model])


    const getSelectItemName = (courtTournamentEvent: CourtTournamentEventMatchResponse, isRender: boolean) => {
        const {tournamentEventMatch} = courtTournamentEvent
        const {homePlayers, awayPlayers} = tournamentEventMatch
        const joinSeparator = ", "
        const tournamentEvent = props.tournamentEvents.find(x => x.tournamentEventId === tournamentEventMatch.tournamentEventId)
        const getdisplayName =(player: PlayerDataBasic) => {
            return `${player?.playerNumber} ${player?.name} ${player?.clubName}`
        }
        const style={color: mode == 'light' ? 'initial' : 'white'}
        const homeDisplay = `${homePlayers.map(getdisplayName).join(joinSeparator)}`
        const awayDisplay = `${awayPlayers.map(getdisplayName).join(joinSeparator)}`
        if(!isRender){
            return `${tournamentEvent.disciplineCode} ${homeDisplay} ${awayDisplay}`
        }
        const hasAssign = courtTournamentEvent.courtId > 0
        return (
            <React.Fragment>
                <Grid container spacing={3} xs={12}>
                    <Grid item xs={4}>
                        <label style={style}>{homeDisplay}</label>
                    </Grid>
                    <Grid item xs={4}>
                        <label style={style}>{awayDisplay}</label>
                    </Grid>
                    {hasAssign && 
                        <Grid item xs={4}>
                            <label style={style}>Allerede tildelt til: {courtTournamentEvent.venueName} - {courtTournamentEvent.courtNumber}</label>
                        </Grid>
                    }                   
                </Grid>
            </React.Fragment>
        )
    }
    
    const renderOption = (option: SelectItem) => {
            const courtMatchState = courtsMatchesState.model
            .find(x => getSelectItemId(
                x.tournamentEventMatch.tournamentEventId, x.tournamentEventMatch.matchId
            ) === option.value)
            return getSelectItemName(courtMatchState, true)
        }

    return(
        <React.Fragment>
        {selectItems && !courtsMatchesState.isLoading &&
            <FormControl>
                <Autocomplete
                className={classes.formControl}
                noOptionsText={"Mangel på værdier"}
                multiple={false}                                        
                PopperComponent = {PopperMy}
                fullWidth={true}
                renderOption={(props, option: SelectItem) => renderOption(option)}
                id={`courtMatchesForm-${props.tournamentEventId}`}
                isOptionEqualToValue={(option: SelectItem, value: SelectItem) => option.value == value.value}
                options={selectItems}
                getOptionLabel={(option: SelectItem) => option.name}
                onChange={(event: any, option: any) => handleAssign(option)}
                renderInput={(params) => <TextField {...params} label={"Vælg spillere"} />}
                />
            </FormControl> 
        }
        {
           courtsMatchesState.isLoading && <Skeleton variant="rectangular" />
        }
    </React.Fragment>
    )
}