import React, { useRef, useState } from "react";
import { TablePaginationActions } from "./tableStyles";
import { default as lodash } from 'lodash';
import makeStyles from "../../../components/muiComponents/core/makeStyles";
import { Table, Theme } from "@mui/material";
import TableRow from "../../../components/muiComponents/core/TableRow";
import TableCell from "../../../components/muiComponents/core/TableCell";
import Skeleton from "../../../components/muiComponents/core/Skeleton";
import TableContainer from "../../../components/muiComponents/core/TableContainer";
import Paper from "../../../components/muiComponents/core/Paper";
import TableHead from "../../../components/muiComponents/core/TableHead";
import TableBody from "../../../components/muiComponents/core/TableBody";
import TableFooter from "../../../components/muiComponents/core/TableFooter";
import TablePagination from "../../../components/muiComponents/core/TablePagination";

type Props = {
  footerLabel: string,
  tableLabel: string
  data: SportResultsTableRow[],
  summaryRows?: SportResultsTableSummaryRow[],
  onRowSelect?: (item: any) => void,
  onRowSelectPreventCols?: string[]
  objectLoadingId?: any,
  headers: JSX.Element[],
  passFooter?: boolean,
  isRowsLoading?: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& th': {
      fontWeight: 'bold',
      fontSize: 12
    },

    '& tr .MuiTableCell-body': {
      cursor: 'pointer'
    }
  }
}));

const onRowSelect = (e: any, func: Function, row: SportResultsTableRow, shouldPreventCols: string[]) => {
  const eventShouldBeBlocked = shouldPreventCols?.some(x => {
    const name = e?.target?.name;
    return x === name;
  })
  if (!eventShouldBeBlocked && func) {
    func(row)
  }
}

const getTableBody = (rows: SportResultsTableRow[], props: Props) => {
  const jsxRows = rows.map((row, index) => {
    return (
      <TableRow className={"table-row"} hover key={row?.id} onClick={(e: any) => onRowSelect(e, props.onRowSelect, row, props.onRowSelectPreventCols)}>
        {props.objectLoadingId === row?.id ?
          <TableCell key={index} colSpan={5}>
            <Skeleton variant="rectangular" animation="wave" />
          </TableCell>
          :
          getTableCells(row.cells)
        }
      </TableRow>
    )
  });
  return (
    <React.Fragment>
      {jsxRows && jsxRows}
    </React.Fragment>
  );
}

const getLoadingRows = () => {
  let result = new Array<JSX.Element>();
  const maxRows = 10
  for (let index = 0; index < maxRows; index++) {
    result.push(
      <TableRow hover key={index}>
        <TableCell key={index} colSpan={6}>
          <Skeleton variant="rectangular" animation="wave" />
        </TableCell>
      </TableRow>
    )
  }
  return (
    <React.Fragment>
      {result}
    </React.Fragment>
  )
}

const getSummaryRows = (rows: SportResultsTableSummaryRow[], props: Props) => {
  const jsxRows = rows.map((row, index) => {
    return (
      <TableRow hover key={row.id}>
        {row.name}
        {row.value}
      </TableRow>
    )
  });
  return jsxRows;
}

const getTableCells = (cells: JSX.Element[]) => {
  const jsxCell = cells.map((cell) => {
    return cell;
  })
  return (
    <React.Fragment>
      {jsxCell && jsxCell}
    </React.Fragment>
  );
}


export const SportResultsTable: React.FC<Props> = (props: Props) => {
  
  const passFooter = props.passFooter === null || props.passFooter === undefined ? true : props.passFooter;
  const classes = useStyles();
  const setInitialPage = () => {
    const sessionPage = sessionStorage.getItem("page") ? new Number(sessionStorage.getItem("page")).valueOf() : 0
    let paginateData: SportResultsTableRow[] = []
    let currentPage = sessionPage
    if (sessionPage == 0) {
      return sessionPage
    }
    while (paginateData.length == 0 && currentPage > 0) {
      currentPage = currentPage - 1
      paginateData = props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    return currentPage
  }
  const [page, setPage] = useState(setInitialPage())
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const tableRef = useRef<any>();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    window['scrollTo']({ top: tableRef.current.offsetTop, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  window.onbeforeunload = (event: any) => {
    setPage(0);
  };

  const rowsDisplayed = props.data && rowsPerPage > 0
    ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : props.data;
  const isNoDataAndNoLoading = lodash.isEmpty(rowsDisplayed) && !props.isRowsLoading;
  if (isNoDataAndNoLoading) {
    return (null);
  }

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table className={classes.table} size="small" aria-label={props.tableLabel} ref={tableRef}>
        <TableHead>
          <TableRow>
            {props.headers && props.headers.map((header) => {
              return header;
            })}
          </TableRow>
        </TableHead>
        {
          props.isRowsLoading &&
          <TableBody>{getLoadingRows()}</TableBody>

        }
        {
          !props.isRowsLoading &&
          <TableBody>
            {rowsDisplayed && getTableBody(rowsDisplayed, props)}
            {props.summaryRows && getSummaryRows(props.summaryRows, props)}
          </TableBody>
        }

        {props.data && passFooter &&
          <TableFooter>
            <TableRow>
              <TablePagination
                labelDisplayedRows={({ from, to, count }) => `${from}-${to ? to : 1} af ${count ? count : 1}`}
                labelRowsPerPage={props.footerLabel}
                rowsPerPageOptions={[25, 50, 100]}
                colSpan={5}
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': `${props.footerLabel}` },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        }
      </Table>
    </TableContainer>
  )
}

export interface SportResultsTableRow {
  cells: JSX.Element[],
  id: any,
  optional?: any
}

export interface SportResultsTableSummaryRow {
  name: JSX.Element,
  value: JSX.Element,
  id: any
}

export interface SportResultsTableCell {
  value: any,
  scope?: string,
  align?: "left" | "center" | "right" | "justify" | "inherit",
}

export interface SportResultsTableHeader {
  id: any,
  name: string,
  align?: "left" | "center" | "right" | "justify" | "inherit",
}