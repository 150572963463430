import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tournament, 
  TournamentClassLiveScoreState,
  TournamentEvent} from "../../commons/tournament/model";
import { RootState } from "../../store/rootReducer";
import { default as lodash } from 'lodash';
import { TournamentEventRowId } from "../../components/tournament/tournamentEvent.table";
import { NavigationState, titleActions } from "../../commons/navigationActionReducer";
import { getLabelDate } from "../../commons/helpers";
import { BaseProps } from "../../routes";
import { useBasePath } from "../../commons/utils/router.utils";
import { AgeGroupTournamentClassDropdown } from "../../components/tournamentClass/ageGroupTournamentClassDropdown";
import { TournamentDetailsList } from "./tournamentEventDetailsList";
import { TournamentDetailsState } from "./tournamentDetailsState";
import { TournamentEventsLiveScore } from "./tournamentEventsLiveScore";
import { TournamentStateManager } from "./tournamentState";
import Switch from "../../components/muiComponents/core/Switch";
import FormControlLabel from "../../components/muiComponents/core/FormControlLabel";
import makeStyles from "../../components/muiComponents/core/makeStyles";
import Grid from "../../components/muiComponents/core/Grid";
import Paper from "../../components/muiComponents/core/Paper";


type props = BaseProps & {

}


export const TournamentEvents: FC<props> = (props) => {
  const styles = makeStyles(() => ({
    tournamentInfoVal: {
      marginLeft: '10%',
      marginRight: '10%'
    },
    tournamentInfoGrid: {
      marginBottom: '20px'
    },
    tournamentInfoPaper: {
      padding: '10px',
    }
  }))();
  const navigate = useNavigate()
  const basePath = useBasePath()
  const params = useParams();
  const { tournamentClassIdStr, tournamentEventIdStr } = params
  const [selectedRowId, setSelectedRowId] = useState<TournamentEventRowId>(null);
  const [isTitleSet, setIsTitleSet] = useState<boolean>(false);
  const [showOnlyEmpty, setShowOnlyEmpty] = useState<boolean>(true);
  const [tournament, setTournament] = useState<Tournament>()
  const dispatch = useDispatch();
  const titleState = useSelector<RootState>(state => state.navigation.model) as NavigationState
  const [tournamentClassId, setTournamentClassId] = useState<number>(Number.parseInt(tournamentClassIdStr))
  const [tournamentEventId, setTournamentEventId] =
    useState<number>(tournamentEventIdStr ? Number.parseInt(tournamentEventIdStr) : undefined)
  const [tournamentEvents, setTournamentEvents] = useState<TournamentEvent[]>([])

  const [tournamentClassLiveState, setTournamentClassLiveState] = useState<TournamentClassLiveScoreState>()
  useEffect(() => {
    const title = 'Tæl turnering';
    const isEmptyRowId = lodash.isEqual(selectedRowId, null)
    if (isEmptyRowId && lodash.isEmpty(titleState?.backwardAction)) {
      dispatch(titleActions.setNavigation(title, undefined, titleState?.forwardAction))
    }
  }, [selectedRowId])

  useEffect(() => {
    if (!isTitleSet) {
      dispatch(titleActions.setNavigation('Tæl turnering'))
      setIsTitleSet(true);
    }
  }, [isTitleSet])
  const onChangeSwitch = (value: any) => {
    setShowOnlyEmpty(new Boolean(value).valueOf())
  }

  const handleBackward = () => {
    setSelectedRowId(null)
  }

  const handleForward = (selectedRowId: TournamentEventRowId) => {
    setSelectedRowId(selectedRowId)
  }

  const onParamChanged = (tournamentClassId: number, tournamentEventId?: number) => {   
    if (tournamentEventId) {
      navigate(`${basePath}/${tournamentClassId}/${tournamentEventId}`)
    } else {
      navigate(`${basePath}/${tournamentClassId}`)
    }

    setTournamentClassId(tournamentClassId)
    setTournamentEventId(tournamentEventId)
  }


  return (
    <React.Fragment>
      <TournamentStateManager
        tournamentClassId={tournamentClassId}
        onStateChange={(model: Tournament) => setTournament(model)}
      />

      <TournamentEventsLiveScore
        tournamentClassId={tournamentClassId}
        tournamentEvents={tournamentEvents}
        onTournamentEventsUpdate={
          (tournamentEvents: TournamentEvent[]) => {
            setTournamentEvents(tournamentEvents)
          }
        }
        onTournamentClassLiveStateUpdate={(liveState: TournamentClassLiveScoreState) => {
          setTournamentClassLiveState(liveState)
        }} 
      />

      <TournamentDetailsState 
        tournamentClassId={tournamentClassId} 
        tournamentEventId={tournamentEventId}
        onStateChanged={(model) => setTournamentEvents(model)} 
      />

      {
        !selectedRowId &&
        <React.Fragment>
          <Grid xs={2} spacing={3}>
            <Grid className={styles.tournamentInfoGrid} item={true} spacing={3}>
              <AgeGroupTournamentClassDropdown defaultTournamentClassId={tournamentClassId}
                handleChange={(event) => onParamChanged(event?.value ?? undefined)}
              />
            </Grid>
            <Grid className={styles.tournamentInfoGrid} item={true} spacing={1}>
              {tournament && <Paper className={styles.tournamentInfoPaper} elevation={1}><strong>Arrangør:</strong> <label>{tournament?.clubName}</label></Paper>}
              {tournament && <Paper className={styles.tournamentInfoPaper} elevation={1}><strong>Turneringsnummer:</strong> <label>{tournament?.tournamentNumber}</label></Paper>}
              {tournament && <Paper className={styles.tournamentInfoPaper} elevation={1}><strong>Dato Fra:</strong> <label>{getLabelDate(tournament?.dateFrom)}</label></Paper>}
              {tournament && <Paper className={styles.tournamentInfoPaper} elevation={1}><strong>Dato Til:</strong> <label>{getLabelDate(tournament?.dateTo)}</label></Paper>}
            </Grid>
          </Grid>
        </React.Fragment>
      }
      {
        !selectedRowId &&
        <React.Fragment>
          <FormControlLabel control={
            <Switch checked={showOnlyEmpty} onChange={(props) => onChangeSwitch(props.currentTarget.checked)} name="showWithoutResult" />
          } label="Vis kampe der mangler resultat" />
          <TournamentDetailsList
            tournamentClassLiveState={tournamentClassLiveState}
            isLivescore={props.isLivescore} isReadonly={props.isReadonly}
            onTournamentEventChanged={(tournamentEventId: number) => onParamChanged(tournamentClassId, tournamentEventId)}
            showOnlyEmpty={showOnlyEmpty} tournamentClassId={tournamentClassId} tournamentEvents={tournamentEvents} 
            isUmpire={props.isUmpire}
          />
        </React.Fragment>
      }
    </React.Fragment>
  )
}