import moment from "moment"
import { PlayerDataBasic } from "../player/model"
import { LiveScoreMatchState } from "../../pages/leagueMatch/leagueMatchSetCounter"

export interface GetTournamentApplicationsAdmin{
    tournamentAdmins: TournamentApplicationAdminView[]
    tournaments: TournamentClass[]
}


export interface TournamentApplicationAdminView{
    tournamentClassID : number
    tournamentID : number

    tournamentNumber : string

    seasonID : number

    dateTo : Date

    lastRegistration? : Date

    roundID? : number

    regionID? : number

    tournamentState : number

    tournamentType : number

    title : string

    clubName : string
    clubID: number
    clubNumber : string

    recurring? : boolean

    clubUserID? : number

    contactName : string

    contactEmail : string

    weekChanged? : boolean

    dateFrom? : Date

    contactAddress : string

    contactCity : string

    contactPostalCode : string

    contactCountryCode : string

    contactPhoneMobile : string

    contactPhoneWork : string

    contactPhone : string

    comment : string

    geoRegionID? : number

    uRLInvitation : string

    uRLProgram : string

    invitationPDF? : number
    programPDF? : number
    tIActiv? : number
    tIProgram? : number
    liveScore?: boolean
    liveScoreCode?:string
}

export interface AgeGroupClass{
    ageGroupClassId: number
    ageGroupId: number
    classId: number
    ageGroupClassName: string
}

export interface TournamentClass{
    tournamentClassID : number

    tournamentID : number

    ageGroupID : number

    classID : number

    tournamentNumber : string

    ageGroupCode : string

    classCode : string

    tournamentState : number

    MaxEvents : number

    noValidateAgeGroup : boolean

    noValidateClass : boolean

    tournamentClassTitle : string

    BornFrom? : Date

    BornTo? : Date

    tournamentClassInfo : string

    classDateFrom? : Date

    classDateTo? : Date

    rankingPointsFromMale? : number

    rankingPointsToMale? : number

    rankingPointsFromFemale? : number

    rankingPointsToFemale? : number

    validationRule? : number
}


export class TournamentPatchRequest{
    regionIdList: number[]
    dateFrom: Date
    dateTo: Date

    ageGroupList: number[]

    classIdList: number[]

    clubIds?: number[]

    tournamentStateList: number[]
    tournamentClassStateList: number[]

    tournamentTypeList: number[]
    disciplineCodeList: string[] 
    geoRegionIdList: number[]

    tournamentId: number;
    tournamentClassId: number;

    constructor(){
        const currentDate = moment(new Date())
        this.regionIdList = null;
        this.dateFrom = currentDate.toDate();
        this.dateTo = currentDate.add('week', 1).toDate() ;
        this.ageGroupList = null;
        this.classIdList = null;
        this.clubIds = null;
        this.tournamentStateList = null;
        this.tournamentClassStateList = null;
        this.tournamentTypeList = null;
        this.geoRegionIdList = null;
    }
}

export interface LiveScoreTournamentMatch{
     tournamentId : number
     matchId : string
     tournamentEventId : number
     level? : number
     group? : number
     playOffIndex? : number
     playoffName : string
     players : string
     score : string
     walkOver : boolean
     winner : number
     updatedTime : Date
     reserved : boolean
     liveScoreUserId? : number
     completed : boolean
}

export interface TournamentEventMatch{
    tournamentEventId : number
    matchId : string
    level? : number
    group? : number
    winnerProgramNumber : number
    loserProgramNumber? : number
    startTime? : Date
    endTime? : Date
    result : string
    walkOver : boolean
    playOffIndex : number
    playoffName : string
    loserPlayers: TournamentEventPlayer[]
    winnerPlayers: TournamentEventPlayer[]

    sortIndex? : number
    levelText? : string
}

export interface TournamentEventPlayer{
    tournamentEventId : number
    programNumber : number
    playerId : number
    clubId? : number
    ranking? : number
    points : number
    pointsCount : number
    playOffIndex? : number
    playoffName : string
    updatedByUserId? : number
    updatedTime? : Date
    tournamentEventPlayerId : number
    deleted : boolean
    points2? : number
    clubName : string
    player : PlayerDataBasic
}

export interface Tournament{
    tournamentId: number;
    clubId: number;
    clubName: string,
    tournamentNumber: string;
    dateFrom: Date;
    dateTo: Date
}

export interface TournamentEvent{
    tournamentEventId : number
    tournamentClassId : number
    disciplineCode : string
    sortIndex : number
    price : number
    maxRegistrations? : number

    tournamentClass : TournamentClass
    liveScoreTournamentMatch : LiveScoreTournamentMatch[]
    doneTournamentEventMatch : TournamentEventMatch[]
    tournamentEventPlayer : TournamentEventPlayer[]
    unifiedTournamentMatches: UnifiedTournamentMatch[]
}

export interface UnifiedTournamentMatch{
    matchId: number;
    tournamentEventId: number
    matchCode: number;
    matchTime?:Date
    scoreStatus?: number
    winner?: number
    groupNumber?: number
    levelNumber?: number
    created?: Date
    updated?: Date
    levelText?: string
    sortIndex?: number
    result?: string
    homePlayers: PlayerDataBasic[]
    awayPlayers: PlayerDataBasic[]
    liveScore?:boolean,
    isPossibleToCount: boolean
}

export enum TournamentLevelEnum{
    Final = 1,
    Semifinal = 2,
    Quaterfinals = 4
}
export type TournamentClassLiveScoreState = {[tournamentEventId: number]: {[matchId: number] : LiveScoreMatchState}}
