import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownResourceType, SelectItem } from "../../commons/common.types";
import { AgeGroupDropdown } from "../../components/ageGroup/ageGroupDropdown";
import { ClassGroupDropdown } from "../../components/classGroup/classGroupDropdown";
import { RegionDropdown } from "../../components/regions/region.dropdown";
import { RootState } from "../../store/rootReducer";
import SearchIcon from '@mui/icons-material/Search';
import { tournamentActions } from "../../commons/tournament/tournamentActionReducer";
import { GetTournamentApplicationsAdmin, TournamentPatchRequest } from "../../commons/tournament/model";
import { default as lodash } from 'lodash';
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { responseHandlerActions } from "../../commons/responseHandler/responseHandlerActionReducer";
import { TournamentTable } from "../../components/tournament/tournament.table";
import { titleActions } from "../../commons/navigationActionReducer";
import { ClubDropdown } from "../../components/clubs/club.dropdown";
import { BaseProps } from "../../routes";
import Skeleton from "../../components/muiComponents/core/Skeleton";
import TextField from "../../components/muiComponents/core/TextField";
import Button from "../../components/muiComponents/core/Button";
import Grid from "../../components/muiComponents/core/Grid";
import makeStyles from "../../components/muiComponents/core/makeStyles";


type props = BaseProps & {

}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  }
}));


export const TournamentSearch: FC<props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState<boolean>();
  const [searchParameters, setSearchParameters] = useState<TournamentPatchRequest>(new TournamentPatchRequest());
  const tournamentsState = useSelector<RootState>((state) => state.tournaments.tournaments) as ReducerStateProperties<GetTournamentApplicationsAdmin>
  const [isInit, setIsInit] = useState<boolean>(true);
  useEffect(() => {
    if (isInit) {
      dispatch(titleActions.setNavigation('Vælg en turnering'));
      setIsInit(false);
    }
  }, [isInit])
  const singleVal = (event: SelectItem) => {
    return !lodash.isEmpty(event) ? [event.value] : null;
  }
  const multipleVal = (event: SelectItem[]) => {
    return !lodash.isEmpty(event) ? event.map(x => x.value) : null;
  }
  const handleChangeDropdowns = (event: SelectItem[] | SelectItem, resource: DropdownResourceType) => {
    switch (resource) {
      case DropdownResourceType.Club:
        setSearchParameters({
          ...searchParameters,
          clubIds: multipleVal(event as SelectItem[])
        });
        break;
      case DropdownResourceType.Region:
        setSearchParameters({
          ...searchParameters,
          regionIdList: multipleVal(event as SelectItem[])
        });
        break;
      case DropdownResourceType.AgeGroup:
        setSearchParameters({
          ...searchParameters,
          ageGroupList: singleVal(event as SelectItem)
        });
        break;
      case DropdownResourceType.ClassGroup:
        setSearchParameters({
          ...searchParameters,
          classIdList: singleVal(event as SelectItem)
        });
        break;
      case DropdownResourceType.GeoRegion:
        setSearchParameters({
          ...searchParameters,
          geoRegionIdList: multipleVal(event as SelectItem[])
        });
        break;
      case DropdownResourceType.Discipline:
        setSearchParameters({
          ...searchParameters,
          disciplineCodeList: multipleVal(event as SelectItem[])
        });
        break;
      default:
        break;
    }
  }

  const onFieldChange = (name: string, value: any) => {
    setSearchParameters({
      ...searchParameters,
      [name]: !lodash.isEmpty(value) ? value : null
    })
  }

  const isfilterDataLoading = useSelector<RootState>((state) => {
    const { ageGroups, regions, geoRegions, classGroups } = state;
    return (ageGroups.isLoading && ageGroups.loadStarted)
      || (regions.isLoading && regions.loadStarted)
      || (geoRegions.isLoading && geoRegions.loadStarted)
      || (classGroups.isLoading && classGroups.loadStarted)
  }) as boolean

  const handleSearch = () => {
    dispatch(
      tournamentActions
        .get(searchParameters)
    )
    setSubmitted(true);
  }
  useEffect(() => {
    if (lodash.isEmpty(tournamentsState.model) && tournamentsState.status === RequestStatus.Ok && submitted) {
      dispatch(responseHandlerActions.call({ success: true, warningMsg: 'Manglende resultater for disse parametre, som har sat livescore-koden' }))
      setSubmitted(false);
    }
  }, [tournamentsState])
  const isLoadingMatches = (tournamentsState.isLoading && tournamentsState.loadStarted)

  const processDate = (date?: Date, dayOffset?: number): string => {
    const dateMoment = moment(date ?? new Date());
    if (dayOffset) {
      dateMoment.add('days', dayOffset)
    }
    return dateMoment.format("YYYY-MM-DD")
  }



  return (
    <React.Fragment>
      <Grid container spacing={3} xs={6} md={6} xl={6}>
        <Grid item xs={12} md={12} xl={12}>
          <ClubDropdown isMultiple={true}
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.Club)} />
          <AgeGroupDropdown
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.AgeGroup)} />
          <ClassGroupDropdown
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.ClassGroup)} />
          <RegionDropdown multiple={true}
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.Region)} />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {!isfilterDataLoading && <TextField name="dateFrom" value={moment(searchParameters.dateFrom).format("YYYY-MM-DD")} label="Dato fra"
            type="date" InputLabelProps={{ shrink: true }} autoComplete="off" fullWidth onChange={(props) => onFieldChange(
              props.currentTarget.name, props.currentTarget.value)} />}
          {isfilterDataLoading && <Skeleton variant='rectangular' />}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {!isfilterDataLoading && <TextField name="dateTo" value={moment(searchParameters.dateTo).format("YYYY-MM-DD")} label="Dato til" type="date" InputLabelProps={{ shrink: true }} autoComplete="off"
            fullWidth onChange={(props) => onFieldChange(
              props.currentTarget.name, props.currentTarget.value)} />}
          {isfilterDataLoading && <Skeleton variant='rectangular' />}
        </Grid>
        <Grid item xs={10}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            endIcon={<SearchIcon />}
            disabled={isfilterDataLoading && isLoadingMatches}
            onClick={handleSearch}>
            Søg
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TournamentTable isLivescore={props.isLivescore} isReadonly={props.isReadonly} isUmpire={props.isUmpire} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
